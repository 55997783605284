@import "../../scss/vars.scss";

.button {
  width: 100%;
  height: 56px;
  border-radius: 16px;
  background-color: $accent;
  font-size: 16px;
  color: $white;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: 0;
}

.button:hover {
  opacity: 0.9;
}
