@import "../../../scss/vars.scss";

.centralBlock {
  width: 63%;
  // height: 732px;
  margin: 48px 0 48px 48px;
  border-radius: 24px;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.2);

  padding: 48px 32px 0px 32px;

  &__title {
    font-size: 24px;
    font-weight: 700;
    color: #2b2d33;
    margin-bottom: 32px;
  }

  &__wrapper {
    display: flex;
  }

  &__left {
    width: 50%;
    height: 100%;
    padding-right: 30px;

    &Select {
      margin-top: 20px;
    }

    &MP3Dropzone {
      margin-top: 32px;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    margin: 35px 0 20px;

    &Main {
      display: flex;
      align-items: center;
      margin: 0px 0 20px;
    }

    &Title {
      font-size: 16px;
      font-weight: 400;
      color: $primary;
      margin-left: 13px;
    }
  }

  &__switch {
    display: flex;
    align-items: center;

    &Title {
      font-size: 14px;
      font-weight: 600;
      color: $primary;
      margin-left: 13px;
    }
  }

  &__btn {
    width: 200px;
    margin-top: 266px;
    margin-bottom: 83px;
  }

  &__right {
    width: 50%;
    height: 100%;
    padding-left: 8px;

    &Item {
      margin-top: 24px;
    }

    &Btn {
      width: 178px;

      &Wrapper {
        margin: 40px 0 120px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
