@import "../../scss/vars.scss";

.inputBlock {
  width: 100%;
  position: relative;

  &__label {
    color: $secondary_text;
    font-weight: 400;
    font-size: 14px;
  }

  .input {
    width: 100%;
    height: 56px;
    background-color: $fieldsBg;
    border-radius: 16px;
    border: none;
    outline: none;
    margin-top: 16px;
    padding: 0 24px;
    font-size: 14px;
    color: $primary;
    font-weight: 500;
    border: 2px solid $fieldsBg;

    &:focus {
      border: 1.5px solid $accent;
      background-color: $white;
    }

    &.error {
      color: $error;
      border: 1.5px solid $error;
      background-color: $white;
    }

    &.error::placeholder {
      color: $error;
    }
  }

  .input::placeholder {
    color: $textDafault;
  }

  &__error {
    color: $error;
    font-weight: 400;
    font-size: 12px;
    position: absolute;
    bottom: -16px;
  }
}
