@import "../../scss/vars.scss";

.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  // background-color: #55603d;
  border: 2px solid $accent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.active {
    background-color: $accent;
    border: 2px solid $accent;
  }

  &.disable {
    cursor: default;
  }
}
