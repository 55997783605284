@import "../../scss/vars.scss";

.modalBlock {
  position: absolute;
  left: 0;
  top: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 300;

  .modal {
    width: 503px;
    height: 200px;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 24px;

    &__title {
      color: #2b2d33;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    &__subtitle {
      color: #2b2d33;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 32px;
    }

    &__bottom {
      display: flex;
      justify-content: flex-end;
    }

    &__cancelBtn {
      width: 101px;
      height: 48px;
      border-radius: 16px;
      border: 2px solid #d5d5d6;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
    }

    &__deleteBtn {
      width: 137px;
      height: 48px;
      border-radius: 16px;
      border: 2px solid #ee505a;
      background-color: #ee505a;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      margin-left: 12px;
    }
  }
}
