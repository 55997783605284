$black: #000;
$primary: #11142d;
$white: #fff;
$fieldsBg: #f8f8f8;
$secondary: #808191;
$textDafault: #b2b3bd;
$error: #ee505a;
$selected: #55603d;
$background: #ffffff;
$signInBg: #f2f8e7;
$accent: #a5d05f;
$secondary_text: #aaabad;

$inter: "Inter", sans-serif;

$tablet-width: 768px;
$phone-width: 375px;
$desktop-width: 1280px;
