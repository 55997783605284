@import "../../scss/vars.scss";

.seachBlock {
  // width: 100%;
  height: 56px;
  background: $fieldsBg;
  border-radius: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-top: 16px;

  &__dandruff {
    margin-left: 16px;
  }

  input {
    padding-left: 18px;
    background: transparent;
    height: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    color: $primary;
    font-weight: 500;

    width: calc(100% - 159px);
  }

  input::placeholder {
    color: $textDafault;
  }

  &__button {
    width: 123px;
  }
}
