@import "../../scss/vars.scss";

.signIn {
  background-color: $signInBg;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__modal {
    width: 516px;
    height: 763px;
    background-color: $white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 100px 117px;

    &Logo {
      width: 277px;
      height: 262px;
      background-color: #c4c4c4;
      // margin: 60px auto;
    }

    &Title {
      color: $primary;
      font-weight: 600;
      margin-top: 24px;
      font-size: 24px;
    }

    &Input {
      width: 100%;
      margin: 32px 0;
    }

    &Button {
      width: 100%;
      margin-top: 40px;
    }
  }
}
