@import "../../../scss/vars.scss";
.centralBlock {
  width: 43%;
  margin: 48px 0 48px 48px;
  border-radius: 24px;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.2);
  padding: 48px;

  &__title {
    font-size: 24px;
    color: $primary;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__field {
    margin-top: 24px;
  }

  &__button {
    width: 200px;
    margin-top: 68px;
  }
}
