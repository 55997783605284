@import "../../../scss/vars.scss";
.rightBlock {
  width: 43%;
  margin: 48px 48px 48px 0;
  border-radius: 24px;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.2);
  padding: 48px;

  &__title {
    font-size: 24px;
    color: $primary;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__list {
    height: 600px;
    overflow-y: auto;
    padding-top: 6px;

    &Item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e4e4e4;
      padding: 24px 0 23px;
    }

    &Button {
      width: 82px;
      margin-right: 20px;
    }

    button {
      height: 36px !important;
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
