@import "../../scss/vars.scss";
.notificationItem {
  width: 375px;
  border-radius: 20px;
  box-shadow: 8px 8px 20px rgba(164, 164, 164, 0.25);
  margin-bottom: 20px;
  margin-left: 5px;
  padding: 12px 16px 24px;

  &__header {
    display: flex;
    align-items: center;

    &Date {
      margin-left: 4px;
      font-size: 12px;
      font-weight: 400;
    }
  }

  &__title {
    font-size: 14px;
    color: $primary;
    font-weight: 500;
    margin-top: 8px;
  }

  &__description {
    font-size: 14px;
    color: #808191;
    font-weight: 400;
    margin-top: 4px;
  }
}
