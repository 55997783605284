.rightBlock {
  width: 43%;
  margin: 48px 48px 48px 0;
  border-radius: 24px;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.2);
  padding: 48px;

  &__list {
    height: 450px;
    overflow-y: auto;

    &Item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e4e4e4;
      padding: 24px 0 23px;
    }

    &Button {
      width: 82px;
      margin-right: 20px;
    }

    button {
      height: 36px !important;
    }
  }
}
