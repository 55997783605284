@import "../../scss/vars.scss";

.prNavigation {
  display: flex;
  background-color: $background;
  height: 100vh;

  &__content {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
  }
}
