.centralBlock {
  width: 100%;
  height: 100%;

  margin-left: 48px;
  margin-top: 48px;
  border-radius: 24px;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.2);
  padding: 20px 32px 32px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Select {
      width: 310px;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    color: #2b2d33;
  }
}
