@import "../../scss/vars.scss";

.tableItem {
  display: flex;
  padding: 40px 0;
  min-height: 80px;
  font-size: 14px;
  white-space: pre-line;
  word-break: break-all;

  &__editBlock {
    position: relative;
    display: flex;
  }

  &.underline {
    border-bottom: 1px solid #e3e6ec;
  }

  &__checkbox {
    display: flex;
    align-items: center;
  }

  img {
    width: 96px;
    max-height: 80px;
    object-fit: contain;
    border-radius: 8px;
  }

  &__date {
    color: #04607d;
    font-weight: 500;
  }

  &__status {
    color: #eb5757;
    &.active {
      color: green;
    }
  }

  &__pen {
    width: 52px;
    height: 52px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    position: absolute;
    top: -16px;
    cursor: pointer;
    border: 2px solid #d5d5d6;
    border-radius: 8px;
  }

  &__box {
    width: 48px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    position: absolute;
    top: -16px;
    left: 73px;
    cursor: pointer;
    border: 2px solid #2b2d33;
    background-color: #2b2d33;
    border-radius: 8px;
  }

  &__title {
    color: #2b2d33;
    font-size: 16px;
    font-weight: 600;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 600;
    color: #808185;
    margin-top: 4px;
  }

  &__openText {
    font-size: 10px;
    font-weight: 400;
    color: $secondary;
    margin-top: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      width: 15px;
    }
  }

  &__type {
    display: flex;
    position: relative;

    span {
      margin-left: 26px;
    }
    &Square {
      width: 20px;
      height: 20px;
      background-color: #6c5dd3;
      border-radius: 4px;
      position: absolute;
      top: -2px;

      &.story {
        background-color: #a0d7e7;
      }
    }
  }
}
