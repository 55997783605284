@import "../../../scss/vars.scss";

.leftPanel {
  min-width: 256px;
  height: 100vh;

  &__title {
    font-size: 32px;
    margin-top: 48px;
    font-weight: 600;
    color: $primary;
    text-align: center;
  }

  &__logo {
    margin-top: 48px;
    width: 196px;
    height: 40px;
    align-self: center;
    margin-left: 30px;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 400;
    margin-top: 48px;
    margin-left: 40px;
    color: $secondary_text;
  }

  &__menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    margin-top: 16px;

    a {
      width: 100%;
      height: 56px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding-left: 22px;

      color: $secondary;
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;

      svg {
        margin-right: 10px;
      }

      & .activeIcon {
        display: none;
      }

      &.active {
        background-color: $accent;
        color: $white;

        & .disactiveIcon {
          display: none;
        }

        & .activeIcon {
          display: block;
        }

        svg {
          path {
            // fill: $white;
          }
        }
      }
    }
  }

  &__logOut {
    width: 256px;
    height: 40px;
    padding-left: 40px;
    // position: fixed;
    margin-top: 252px;
    bottom: 24px;
    display: flex;

    &Name {
      font-size: 14px;
      color: #2b2d33;
      font-weight: 500;
    }

    &Subtitle {
      font-size: 14px;
      color: #808185;
      font-weight: 500;
      margin-top: 8px;
      cursor: pointer;
    }
    img {
      margin-right: 16px;
    }
  }
}
