@import "../../scss/vars.scss";

.switch {
  width: 44px;
  height: 24px;
  border-radius: 12px;
  background-color: #b2b3bd;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  &.active {
    background-color: $accent;
  }

  &__circle {
    width: 22px;
    height: 22px;
    border-radius: 100px;
    background-color: $white;
    margin-left: 1px;
    transition: all 0.3s;

    &.active {
      margin-left: 21px;
    }
  }
}
