@import "../../scss/vars.scss";

.dropZone {
  width: 100%;
  height: 198px;
  border-radius: 12px;
  border: 1px dashed #d5d5d6;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  &__wrapper {
    width: 100%;
  }

  &__label {
    color: #aaabad;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 16px;

    &.focus {
      color: $primary;
    }
  }

  &.active {
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  }

  &__mp3 {
    cursor: pointer;
    width: 232px;
    height: 52px;
    border: 1px solid #e9eaea;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &Text {
      font-size: 16px;
      color: #2b2d333d;
      font-weight: 500;
      margin-left: 8px;
    }

    &Error {
      color: $error;
      font-weight: 600;
      font-size: 12px;
      position: absolute;

      bottom: -20px;
      left: 0;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    color: #808185;
  }

  &__imgPreview {
    width: 100%;
    height: 198px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 100%;
      border-radius: 12px;
    }

    &Close {
      width: 25px;
      height: 25px;
      // background: $white;
      position: absolute;
      border-radius: 100px;
      top: 5px;
      right: 8px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__mp3Preview {
    audio::-webkit-media-controls-play-button,
    audio::-webkit-media-controls-panel {
      background-color: #f7f7f7;
      border-radius: 2px;
    }

    display: flex;
    align-items: center;
    &Close {
      margin-left: 20px;
      cursor: pointer;
    }
  }

  &__error {
    font-size: 14px;
    font-weight: 500;
    color: $error;

    position: absolute;
    bottom: -20px;
    left: 0;
  }
}
