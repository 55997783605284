.rightBlock {
  width: 28.5%;
  // height: 100%;
  margin: 48px 48px 48px 0;
  border-radius: 24px;

  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.2);
  padding: 32px 20px 0;

  &__dropzone {
    // width: 298px;
    margin: 0 auto 48px;
  }

  &__field {
    margin-top: 20px;
  }

  &__checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 16px;

    &Label {
      color: #2b2d33;
      font-size: 16px;
      font-weight: 600;
      margin-right: 20px;
    }
  }

  &__button {
    width: 222px;
    margin: 48px 0;

    &Wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }
}
