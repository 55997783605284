@import "../../scss/vars.scss";

.itemEdit {
  display: flex;
  &__left {
    width: 50%;
    padding-right: 20px;

    &DropZone {
      // width: 298px;
      margin: 0 auto;
    }

    &Checkbox {
      display: flex;
      align-items: center;
      margin: 35px 0 18px;

      &Label {
        color: #aaabad;
        font-weight: 400;
        font-size: 14px;
        margin-left: 13px;
      }
    }
  }

  &__right {
    padding-left: 8px;
    width: 50%;
    padding-right: 25px;
  }

  &__switch {
    display: flex;
    align-items: center;

    &Title {
      font-size: 14px;
      font-weight: 600;
      color: $primary;
      margin-left: 13px;
    }
  }

  &__button {
    width: 221px;
    margin: 48px 0 30px;
  }

  &__textarea {
    margin-top: 16px;
  }

  &__block {
    margin-top: 20px;
  }
}
