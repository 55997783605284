@import "../../../scss/vars.scss";

.tableBody {
  min-height: 0;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    margin-top: 32px;

    &Item {
      font-size: 12px;
      font-weight: 500;
      color: #b2b3bd;
    }
  }

  &__loader {
    margin: 0 auto;
    margin-top: 100px;
  }

  &__noResults {
    margin: 0 auto;
    margin-top: 20px;
    font-weight: 600;
    font-size: 14px;
    color: $secondary;
  }

  &__line {
    height: 0.98px;
    margin-top: 5px;
    background-color: #e4e4e4;
  }

  &__items {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-y: overlay;

    min-height: 0px;
  }

  &__button {
    width: 200px;
    align-self: center;
    margin: 46px auto 46px;
  }
}
