@import "../../scss/vars.scss";

.inputBlock {
  width: 100%;
  position: relative;

  &__label {
    color: $secondary;
    font-weight: 600;
    font-size: 12px;

    &.focus {
      color: $primary;
    }
  }

  .inputWrapper {
    width: 100%;
    height: 56px;
    background-color: $fieldsBg;
    border-radius: 8px;
    border: none;
    outline: none;
    margin-top: 16px;
    font-size: 14px;
    color: $primary;
    font-weight: 500;
    border: 2px solid $fieldsBg;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;

    &.focus {
      border: 2px solid #55603d;
      background-color: $white;
    }

    &.error {
      color: $error;
      border: 2px solid $error;
      background-color: $white;
    }
  }

  .input {
    width: 80%;
    height: 100%;
    background-color: transparent;
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 0 24px;
    font-size: 14px;
    color: $primary;
    font-weight: 500;

    &.error {
      color: $error;
      border: 2px solid $error;
      background-color: $white;
    }

    &.error::placeholder {
      color: $error;
    }

    &__select {
      background: $white;
      width: 30px;
      height: 20px;
    }
  }

  .popup {
    width: 40px;
    height: 30px;
    border-radius: 4px;
    background: $white;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    color: $primary;
    font-weight: 500;

    .list {
      width: 100%;
      padding: 12px 10px 12px;
      background-color: $white;

      &__wrapper {
        max-height: 0px;
        overflow: auto;

        width: 100%;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(calc(100% + 12px));
        transition: all 0.2s;
        z-index: 200;

        &.active {
          max-height: 200px;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__item {
        font-size: 14px;
        font-weight: 600;
        color: $primary;
        padding: 7px 10px;

        border-radius: 4px;

        &:hover {
          background: #55603d;
          color: $white;
        }
      }
    }
  }

  .input::placeholder {
    color: $textDafault;
  }

  &__error {
    color: $error;
    font-weight: 600;
    font-size: 12px;
    position: absolute;
    bottom: -16px;
  }
}
