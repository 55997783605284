@import "../../scss/vars.scss";

.selectBlock {
  width: 100%;
  position: relative;

  &__label {
    color: $secondary_text;
    font-weight: 400;
    font-size: 14px;
  }

  .select {
    width: 100%;
    height: 56px;
    background-color: $fieldsBg;
    border-radius: 16px;
    border: none;
    outline: none;
    margin-top: 16px;
    padding: 0 24px;
    font-size: 14px;
    color: $primary;
    font-weight: 500;
    border: 2px solid $fieldsBg;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.focus {
      border: 1.5px solid $accent;
      background-color: $white;
    }

    &.adaptive {
    }

    &.error {
      color: $error;
      border: 1.5px solid $error;
      background-color: $white;
    }

    &__placeholder {
      color: #aaabad;
      font-weight: 400;

      &.error {
        color: $error;
      }
    }

    &__arrow {
      position: absolute;
      right: 0;
      top: 15px;
      right: 16px;
      transform: rotate(0deg);
      transition: all 0.15s;
      height: 24px;

      &.active {
        transform: rotate(180deg);
      }

      &.adaptive {
      }
    }
  }
  &__error {
    color: $error;
    font-weight: 600;
    font-size: 12px;
    position: absolute;
    bottom: -16px;
  }

  .list {
    width: 100%;
    padding: 12px 10px 12px;

    background-color: $white;

    &__wrapper {
      max-height: 0;
      overflow: auto;

      width: 100%;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(calc(100% + 12px));
      transition: all 0.2s;
      z-index: 200;

      &.active {
        max-height: 200px;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      font-size: 14px;
      font-weight: 600;
      color: $primary;
      padding: 7px 10px;

      border-radius: 4px;

      &:hover {
        background: #55603d;
        color: $white;
      }
    }
  }
}

@media (max-width: 1505px) {
  .selectBlock {
    .select {
      &.adaptive {
        padding: 0 12px;
      }

      &__arrow {
        &.adaptive {
          right: 8px;
        }
      }
    }
  }
}
