@import "../../scss/vars.scss";

.itemEdit {
  display: flex;
  &__left {
    width: 41.5%;
    padding-right: 8px;

    &DropZone {
      // width: 298px;
      margin: 0 auto 16px;
    }

    &Item {
      margin-top: 16px;
    }
  }

  &__central {
    padding-left: 8px;
    width: 41.5%;
    padding-right: 8px;
    &Item {
      margin-top: 16px;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    margin: 12px 0 18px;

    &Title {
      font-size: 16px;
      font-weight: 400;
      color: $primary;
      margin-left: 13px;
    }
  }

  &__switch {
    display: flex;
    align-items: center;

    &Title {
      font-size: 14px;
      font-weight: 600;
      color: $primary;
      margin-left: 13px;
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    margin: 50px 0 30px;
    align-self: flex-end;
    button {
      width: 221px;
    }
  }

  &__textarea {
    padding-bottom: 3px;
  }

  &__block {
    margin-top: 20px;
  }
}
