.postManage {
  width: 91.8%;
  height: 89vh;
  margin: 46px auto 0;
  padding: 48px 32px 16px;
  border-radius: 24px;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;

  &__header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
  }

  &__input{
    width: 350px;
  }
}
