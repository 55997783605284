@import "../../scss/vars.scss";

.areaBlock {
  width: 100%;
  position: relative;

  &__label {
    color: #aaabad;
    font-weight: 400;
    font-size: 14px;

    // &.focus {
    //   color: $primary;
    // }
  }

  .textarea {
    width: 100%;
    height: 164px;
    background-color: $fieldsBg;
    border-radius: 16px;
    border: none;
    outline: none;
    margin-top: 16px;
    padding: 16px 24px;
    font-size: 14px;
    color: $primary;
    font-weight: 500;
    border: 2px solid $fieldsBg;
    resize: none;

    &:focus {
      border: 1.5px solid $accent;
      background-color: $white;
    }

    &.error {
      color: $error;
      border: 1.5px solid $error;
      background-color: $white;
    }

    &.error::placeholder {
      color: $error;
    }
  }

  .textarea::placeholder {
    color: $textDafault;
  }

  &__error {
    color: $error;
    font-weight: 600;
    font-size: 12px;
    position: absolute;
    bottom: -16px;
  }
}
